import React from 'react'
import LayoutStart from '../components/LayoutStart'
import Seo from '../components/Seo'
import { graphql, Link } from 'gatsby'
import { StaticImage, GatsbyImage } from "gatsby-plugin-image"
import { GiBackPain, GiShrimp, GiStopSign } from "react-icons/gi"
import { FaUserInjured, FaFlagCheckered, FaRunning, FaStar } from "react-icons/fa"

// Randomowe sortowanie referencji algorytmem Fisher-Yates'a
function shuffle(array) {
  const length = array == null ? 0 : array.length;

  if (!length) {
    return [];
  }

  let index = -1;

  const lastIndex = length - 1;
  const result = [...array];

  while (++index < length) {
    const rand = index + Math.floor(Math.random() * (lastIndex - index + 1));
    const value = result[rand];
    result[rand] = result[index];
    result[index] = value;
  }

  return result;
}

export default function Home({ data }) {

  const feedback = shuffle(data.feedback.nodes).slice(0, 2);
  const instagram = shuffle(data.instagram.nodes).slice(0, 3);
  
  return (
    <LayoutStart>

      <Seo />

      <section id="czesc">
        <div className='index_box'>
          <h2>Fizjoterapia i trening dla zasiedzianych</h2>
          <span>Czy jesteś we właściwym miejscu?</span>
          <p>Fizjoterapia i trening to kompletny zestaw terapeutyczno-ruchowy, którego potrzebujesz. Skąd to wiem? Zbudowałem to miejsce dla osób takich jak Ty. Mogę się mylić, dlatego sprawdźmy czy faktycznie jesteś we właściwym miejscu. Jeśli chociaż jedno z poniższych stwierdzeń dotyczy Ciebie to zostajesz tu na dłużej i czytasz dalej, zgoda?</p>
          <ul>
            <li><GiBackPain size="2em"/><em>cierpisz z powodu bólu pleców</em></li>
            <li><FaUserInjured size="2em"/><em>nękają Cię kontuzje i urazy</em></li>
            <li><FaFlagCheckered size="2em"/><em>posiadasz ulotną motywację do ćwiczeń</em></li>
            <li><FaRunning size="2em"/><em>Twoja technika ćwiczeń wymaga poprawy</em></li>
            <li><GiShrimp size="2em"/><em>irytuje Cię Twoja zgarbiona sylwetka</em></li>
            <li><GiStopSign size="2em"/><em>masz opór przed wykonywaniem ćwiczeń</em></li>
          </ul>
          <p>Wielu za nas nie potrafi samodzielnie poradzić sobie z bólem, niewiedzą czy motywacją. To normalne. Jesteśmy ludźmi, mamy swoje wzloty i upadki. Czasem potrzebujemy kogoś, kto poprowadzi nas i wesprze na drodze ku byciu zdrowszym i szczęśliwszym.</p>
          <div className='index_button'>
            <Link to="/kontakt" className='button'>Umów wizytę</Link>
          </div>
        </div>
      </section>

      <section>
        <div className='index_box index_background_1'>
          <h2>Fizjoterapia i trening - zestaw kompletny</h2>
          <span>Odzyskaj kontrolę nad sprawnym ciałem</span>

          <div className='index_offer'>

            <Link to="/fizjoterapia-bolu-plecow">
              <div className='index_offer_item'>
                <StaticImage src="../images/offer/fizjoterapia-bol-plecow.jpg" alt="Fizjoterapia i Trening Personalny | Warszawa"/>
                <div>
                  <h3>Fizjoterapia bólu pleców</h3>
                  <p>Fizjoterapia bólu pleców wykorzystująca wielokierunkowe podejście pozwoli Ci osiągnąć trwały efekt przeciwbólowy. Twoje aktywne zaangażowanie w proces terapeutyczny sprawi, że pozbędziesz się bólu, wzmocnisz plecy i zwiększysz codzienną sprawność. Daj sobie szansę na życie bez bólu.</p>
                </div>
              </div>
            </Link>
           
            <Link to="/trening-medyczny">
              <div className='index_offer_item'>
                <StaticImage src="../images/offer/trening-medyczny.jpg" alt="Trening medyczny z fizjoterapeutą"/>
                <div>
                  <h3>Trening medyczny</h3>
                  <p>Trening medyczny stanowi łącznik pomiędzy fizjoterapią, a stanem optymalnej sprawności i wydolności organizmu. Pozwala wykryć dysfunkcje ruchowe oraz stwarza możliwości ich wyleczenia. Uczy poprawnego wykonywania ćwiczeń, dostosowując poziom trudności do Twoich możliwości.</p>
                </div>
              </div>
            </Link>

            <Link to="/trening-medyczny">
              <div className='index_offer_item'>
                <StaticImage src="../images/offer/trening-personalny.jpg" alt="Trening personalny z fizjoterapeutą"/>
                <div>
                  <h3>Trening personalny</h3>
                  <p>Trening personalny wspiera Cię w dążeniu do osiągnięcia zaplanowanych celów. Kształuje Twoje ciało, gdy gubisz nadmiarowe kilogramy lub budujesz silne mięśnie. Hartuje umysł pozwalając poskromić nietrwałą motywację. Pomaga odnaleźć się w świecie ćwiczeń, gdy nie wiesz co robić.</p>
                </div>
              </div> 
            </Link>

          </div>
          <div className='index_button'>
            <Link to="/kontakt" className='button'>Umów wizytę</Link>
          </div>
        </div>
      </section>

      <section>
        <div className='index_box index_background_2'>
          <h2>Fizjoterapia i trening w praktyce</h2>
          <span>Rekomendacje osób, które już skorzystały z moich usług*</span>

          <div className='index_feed'>
            {feedback.map((feedback, index) => (
              <div className='index_feed_item' key={index}>
                <span><FaStar /> <FaStar /> <FaStar /> <FaStar /> <FaStar /></span>
                <p>{feedback.frontmatter.opinion}</p>
                <div className='index_feed_client'>{feedback.frontmatter.client}</div>
                <div className='index_feed_job'>{feedback.frontmatter.profession}</div>
              </div>
              ))}
          </div>

          <div className='index_feed_source'>
            <p>*Cytowane słowa pochodzą z wizytówki umieszczonej w serwisie <a href="https://goo.gl/maps/4H6dS7wtz8Ue3JSh9" target="_blank" rel="noreferrer">Google Maps</a>; profilów w serwisie <a href="https://www.facebook.com/FizjoPersonalny/" target="_blank" rel="noreferrer">Facebook</a>, <a href="https://www.instagram.com/FizjoPersonalny/" target="_blank" rel="noreferrer">Instagram</a>, <a href="https://www.znanylekarz.pl/lukasz-kalenczuk/fizjoterapeuta/warszawa" target="_blank" rel="noreferrer">Znany Lekarz</a> oraz wiadomości prywatnych.</p>
          </div>

          <div className='index_button'>
            <Link to="/kontakt" className='button'>Umów wizytę</Link>
          </div>
        </div>
      </section>

      <section>
        <div className='index_box'>
          <h2>Fizjoterapia i trening w mediach społecznościowych</h2>
          <span>Materiały wspierające codzienną aktywność fizyczną</span>

          <div className='index_ig'>
            {instagram.map((instagram, index) => (
              <a href={instagram.frontmatter.post_link} key={index} target="_blank" rel="noreferrer" aria-label={instagram.frontmatter.post_alt}>
              <div className='index_ig_item' key={index}>
                <GatsbyImage image={instagram.frontmatter.post_img.childImageSharp.gatsbyImageData}/>
              </div>
              </a>
            ))}
          </div>

          <div className='index_button'>
            <Link to="/kontakt" className='button'>Umów wizytę</Link>
          </div>
        </div>
      </section>
  
    </LayoutStart>
  );
}

export const query = graphql`
query Home {
  feedback: allMarkdownRemark(
    filter: {fileAbsolutePath: {regex: "/(feedback)/"}}
  ) {
    nodes {
      frontmatter {
        client
        profession
        opinion
        source
      }
    }
  }
  instagram: allMarkdownRemark(
    filter: {fileAbsolutePath: {regex: "/(instagram)/"}}
  ) {
    nodes {
      frontmatter {
        post_link
        post_alt
        post_img {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED)
          }
        }
      }
    }
  }
}
`