import React from 'react'
import NavbarGlobal from './NavbarGlobal'
import { Link } from 'gatsby'
import { StaticImage } from "gatsby-plugin-image"

export default function HeaderStart() {
  return (

    <div className='header_gradient'>
      <NavbarGlobal />

      <div className='index_intro_grid'>

        <div className='index_intro_left'>
          <span>Odbuduj codzienną sprawność</span>
          <h1>Fizjoterapia i trening personalny zgrane z Twoimi potrzebami</h1>
          <p>Jestem wyspecjalizowanym fizjoterapeutą i trenerem personalnym, który koncentruje się na tym, aby pomóc Ci odzyskać sprawność i dobre samopoczucie.</p>
          <div className='index_intro_buttons'>
            <Link to="/kontakt" className='button'>Umów wizytę</Link>
            <Link to="#czesc" className='button index_button_scroll'>Przewiń dalej</Link>
          </div>
        </div>

        <div className='index_intro_right'>
          <StaticImage src="../images/lukasz.png" alt="Fizjoterapia i Trening Personalny | Warszawa" />
        </div>

      </div>

      
    </div>
    
  )
}